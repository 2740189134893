import { Perk } from 'app/components/primitive/SubscriptionPanel'
import { gql } from 'graphql-request'

interface IPerk {
  perk: string
  order: number
  applicableTo: string[]
}

export const createPerksQuery = () => gql`
  query getPerks {
    Perks(limit: 100, sort: "order") {
      docs {
        perk
        order
        applicableTo
      }
    }
  }
`

const tiersMap = {
  free: 'Initiate',
  '_1': '1st Degree',
  '_2': '2nd Degree',
  '_3': '3rd Degree',
}

export const mapPerks = (doc: any): null | Perk[] => {
  const perks = doc?.Perks?.docs || []

  if (!perks) return null

  const parsedPerks: Perk[] = perks
    .map((doc: IPerk) => ({
      title: doc?.perk,
      tiers: doc?.applicableTo?.map((tier) => tiersMap[tier]),
      order: doc?.order,
    }))
    .sort((a: IPerk, b: IPerk) => a.order - b.order)

  return parsedPerks
}
