import {
  H2,
  isWeb,
  Paragraph,
  ScrollView,
  SizableText,
  Stack,
  styled,
  XGroup,
  XStack,
  XStackProps,
  YStack,
} from '@mythical/ui'
import { Tier, Frequency } from 'app/queries/tiers/member-tiers'
import React, { useEffect, useState } from 'react'

import { Button, ButtonLink } from '../Button'
import { IconCheckCircle, IconMinus } from '../Icon'

export type Tiers = 'Initiate' | '1st Degree' | '2nd Degree' | '3rd Degree'

export type Perk = {
  title: string
  tiers: Tiers[]
}

export type SubscriptionPanelProps = {
  perks?: Perk[]
  memberTiers?: Tier[]
  initialLevel?: number
  hideInitiateTier?: boolean
}

export const tiersList = ['Initiate', '1st Degree', '2nd Degree', '3rd Degree']

const PLAN_ORDER_MAP = {
  monthly: 1,
  quarterly: 2,
  annually: 3,
}

const SubscriptionPanel = ({
  perks,
  memberTiers,
  initialLevel,
  hideInitiateTier,
}: SubscriptionPanelProps) => {
  const initialTier = initialLevel ? tiersList[initialLevel] : 'Initiate'
  const [selectedTier, setSelectedTier] = useState<Tiers>(
    (initialTier || 'Initiate') as Tiers
  )
  useEffect(() => {
    if (initialTier) {
      setSelectedTier(initialTier as Tiers)
    }
  }, [initialTier])

  const prices =
    memberTiers
      ?.sort((a, b) => a.planLevel - b.planLevel)
      ?.reduce(
        (acc, tier) => {
          const plans = tier.memberfulPlans
            ?.sort((a, b) => PLAN_ORDER_MAP[a.type] - PLAN_ORDER_MAP[b.type])
            ?.reduce((acc, plan) => {
              // If there is no price, don't include it
              if (!plan.price) {
                return acc
              }

              return {
                ...acc,
                [plan.type]: plan,
              }
            }, {})

          return {
            ...acc,
            [tier.name]: plans,
          }
        },
        { Initiate: {} }
      ) || {}

  const priceTiers = Object.keys(prices) as Tiers[]
  const visiblePriceTiers = hideInitiateTier ? priceTiers.slice(1) : priceTiers

  const selectedTierPrice = prices[selectedTier]
  const selectedLevel = tiersList?.findIndex((tier) => tier === selectedTier)
  const tier = memberTiers?.find((tier) => tier.planLevel === selectedLevel)

  // If there are no prices, don't render anything
  if (Object.keys(prices).length === 0) {
    return null
  }

  return (
    <YStack jc="center" ai="center" bg="$secondary10" space="$3" w="100%">
      <H2
        textAlign="center"
        color="$primary10"
        size="$8"
        fontWeight="700"
        maxWidth={768}
        mx="auto"
        $gtSm={{
          size: '$9',
          fontWeight: '700',
        }}
      >
        How curious are you?
      </H2>
      <Paragraph
        size="$3"
        textAlign="center"
        maxWidth={768}
        mx="auto"
        color="$secondary5"
      >
        No hidden fees. You can switch plans or cancel at anytime.
      </Paragraph>

      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={isWeb}
        w="100%"
        mx="auto"
        $gtXxs={{
          w: 'auto',
        }}
      >
        <YStack space="$2" ai="center" jc="center">
          <XGroup overflow="hidden" jc="center" bg="$secondary8" size="$3">
            {visiblePriceTiers.map((tier) => (
              <Button
                key={tier}
                w={90}
                px="$1"
                py="$1"
                fontSize="$1"
                theme={tier === selectedTier ? 'secondary7' : 'tertiary'}
                onPress={() => setSelectedTier(tier)}
                color={tier === selectedTier ? '$secondary1' : '$secondary4'}
                $gtXs={{
                  w: 140,
                  fontSize: '$3',
                }}
                $gtSm={{
                  w: 160,
                }}
              >
                {tier}
              </Button>
            ))}
          </XGroup>

          <XGroup jc="center" bg="$secondary10" borderRadius="$3">
            {visiblePriceTiers.map((tier) => {
              const isActive = tier === selectedTier
              const activeColor = isActive ? '$primary1' : '$secondary6'
              return (
                <Stack
                  key={tier}
                  jc="center"
                  ai="center"
                  w={90}
                  py="$2"
                  px="$3"
                  $gtXs={{
                    w: 160,
                  }}
                >
                  {prices[tier].monthly?.price ? (
                    <XStack jc="center" ai="center">
                      <SizableText
                        size="$4"
                        color={activeColor}
                        fontWeight="700"
                        $gtXs={{
                          size: '$7',
                          fontWeight: '700',
                        }}
                      >
                        ${prices[tier].monthly?.price}
                      </SizableText>
                      <SizableText
                        textAlign="center"
                        color={activeColor}
                        size="$2"
                        whiteSpace="nowrap"
                        $gtXs={{
                          size: '$3',
                        }}
                      >
                        /monthly
                      </SizableText>
                    </XStack>
                  ) : (
                    <SizableText
                      textAlign="center"
                      color={activeColor}
                      size="$3"
                    >
                      Free!
                    </SizableText>
                  )}
                </Stack>
              )
            })}
          </XGroup>
        </YStack>
      </ScrollView>

      <XStack
        fd="column"
        jc="flex-start"
        ai="flex-start"
        $gtSm={{
          flexWrap: 'wrap',
          fd: 'row',
        }}
      >
        {perks?.map((perk) => (
          <Perk
            key={perk.title}
            py="$2"
            jc="flex-start"
            title={perk.title}
            isActive={perk.tiers.includes(selectedTier)}
            $gtSm={{
              flexBasis: '50%',
              px: '$3',
            }}
          />
        ))}
      </XStack>

      <XStack w="100%">
        {selectedTier === 'Initiate' && (
          <XStack mt="$5" px="$1.5" py="$2" w="100%">
            <ButtonLink
              w="100%"
              href="https://mythicalsociety.memberful.com/register"
            >
              Join for Free!
            </ButtonLink>
          </XStack>
        )}
        {selectedTier !== 'Initiate' && (
          <XStack w="100%" flexWrap="wrap">
            {Object.keys(selectedTierPrice).map((frequency) => {
              const plan = tier?.memberfulPlans?.find(
                (plan) => plan?.type === frequency
              )
              return (
                <SubscriptionButton
                  key={`${plan}:${frequency}`}
                  px="$1.5"
                  py="$2"
                  disabled={!plan}
                  price={selectedTierPrice[frequency]?.price}
                  frequency={frequency as Frequency}
                  savings={selectedTierPrice[frequency].savings}
                  smallPrint={selectedTierPrice[frequency].smallPrint}
                  href={`https://mythicalsociety.memberful.com/checkout?plan=${plan?.externalId}`}
                />
              )
            })}
          </XStack>
        )}
      </XStack>
      <SizableText
        textAlign="center"
        color="$secondary5"
        size="$2"
        fontStyle="italic"
        fontWeight="300"
      >
        *Monthly 3rd Degree members must complete 3 consecutive payment cycles
        to receive; Quarterly and Annual Plan members qualify automatically
      </SizableText>
    </YStack>
  )
}

export default SubscriptionPanel

interface PerkProps extends XStackProps {
  title: string
  isActive?: boolean
}

const PerkLabel = styled(SizableText, {
  color: '$secondary7',
  size: '$3',
  flexGrow: 1,
  flexShrink: 1,
  w: '100%',
  selectable: true,
  variants: {
    isActive: {
      true: {
        color: '$primary1',
      },
    },
  } as const,
})

const Perk = ({ title, isActive, ...props }: PerkProps) => {
  return (
    <XStack ai="center" jc="center" space="$2" {...props}>
      {isActive ? (
        <IconCheckCircle flexShrink={0} color="$primary10" />
      ) : (
        <IconMinus flexShrink={0} color="$secondary7" />
      )}
      <PerkLabel isActive={isActive}>{title}</PerkLabel>
    </XStack>
  )
}

type SubscriptionButtonProps = {
  price?: string
  frequency: Frequency
  savings?: string
  smallPrint?: string
  href: string
  disabled?: boolean
  text?: string
} & XStackProps

const SubscriptionButton = ({
  price,
  frequency,
  savings,
  smallPrint,
  href,
  disabled,
  ...props
}: SubscriptionButtonProps) => {
  return (
    <YStack space="$2" jc="center" ai="center" flexGrow={1} {...props}>
      <SizableText
        textAlign="center"
        size="$3"
        color="$primary1"
        textTransform="uppercase"
        opacity={savings ? 1 : 0}
      >
        Save {savings}%
      </SizableText>
      <ButtonLink
        w="100%"
        href={href}
        fontSize="$2"
        $xs={{
          px: '$1',
        }}
        disabled={disabled}
      >
        <SizableText
          size="$5"
          $gtXs={{
            size: '$7',
          }}
          textTransform="lowercase"
        >
          {price && `$${price}`}
        </SizableText>
        /{frequency}
      </ButtonLink>
      {smallPrint && (
        <SizableText color="$secondary6" size="$1">
          {smallPrint}
        </SizableText>
      )}
    </YStack>
  )
}
