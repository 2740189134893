import React, { memo } from 'react'
import { Path } from 'react-native-svg'

import { IconBase } from '../IconBase'
import { IconProps } from '../IconProps'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path d="M19 13H5v-2h14v2Z" fill={`${color}`} />
    </IconBase>
  )
}

Icon.displayName = 'IconMinus'

export const IconMinus = memo<IconProps>(themed(Icon))
