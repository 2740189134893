import { gql } from 'graphql-request'

export const createTiersQuery = () => gql`
  {
    MemberTiers {
      docs {
        id
        memberfulPlans {
          memberfulPlan {
            externalId
            type
            price
            savings
            smallPrint
          }
        }
        planLevel
        name
      }
    }
  }
`

export type Frequency = 'monthly' | 'quarterly' | 'annually'

export interface Tier {
  id: string
  name: string
  planLevel: number
  memberfulPlans: {
    type: Frequency
    externalId: string
    price: string
    savings: string
    ctaLabel: string
    smallPrint: string
  }[]
}

export const mapToTiers = (data: any): Tier[] => {
  return data.MemberTiers.docs.map((tier: any) => ({
    id: tier.id,
    name: tier.name,
    planLevel: tier.planLevel,
    memberfulPlans: tier.memberfulPlans.map(
      (memberfulPlan: any) => memberfulPlan.memberfulPlan
    ),
  }))
}
